// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-event-gallery-js": () => import("./../../../src/pages/event-gallery.js" /* webpackChunkName: "component---src-pages-event-gallery-js" */),
  "component---src-pages-event-js": () => import("./../../../src/pages/event.js" /* webpackChunkName: "component---src-pages-event-js" */),
  "component---src-pages-forgot-password-js": () => import("./../../../src/pages/forgot-password.js" /* webpackChunkName: "component---src-pages-forgot-password-js" */),
  "component---src-pages-forgot-username-js": () => import("./../../../src/pages/forgot-username.js" /* webpackChunkName: "component---src-pages-forgot-username-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kalanjiyum-js": () => import("./../../../src/pages/kalanjiyum.js" /* webpackChunkName: "component---src-pages-kalanjiyum-js" */),
  "component---src-pages-kyts-events-js": () => import("./../../../src/pages/kyts-events.js" /* webpackChunkName: "component---src-pages-kyts-events-js" */),
  "component---src-pages-kyts-members-js": () => import("./../../../src/pages/kyts-members.js" /* webpackChunkName: "component---src-pages-kyts-members-js" */),
  "component---src-pages-legal-privacy-policy-js": () => import("./../../../src/pages/legal/privacy-policy.js" /* webpackChunkName: "component---src-pages-legal-privacy-policy-js" */),
  "component---src-pages-legal-refund-policy-js": () => import("./../../../src/pages/legal/refund-policy.js" /* webpackChunkName: "component---src-pages-legal-refund-policy-js" */),
  "component---src-pages-legal-terms-of-service-js": () => import("./../../../src/pages/legal/terms-of-service.js" /* webpackChunkName: "component---src-pages-legal-terms-of-service-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-members-app-js": () => import("./../../../src/pages/members/app.js" /* webpackChunkName: "component---src-pages-members-app-js" */),
  "component---src-pages-members-banners-js": () => import("./../../../src/pages/members/banners.js" /* webpackChunkName: "component---src-pages-members-banners-js" */),
  "component---src-pages-members-events-js": () => import("./../../../src/pages/members/events.js" /* webpackChunkName: "component---src-pages-members-events-js" */),
  "component---src-pages-members-food-items-js": () => import("./../../../src/pages/members/food-items.js" /* webpackChunkName: "component---src-pages-members-food-items-js" */),
  "component---src-pages-members-gallery-js": () => import("./../../../src/pages/members/gallery.js" /* webpackChunkName: "component---src-pages-members-gallery-js" */),
  "component---src-pages-members-index-js": () => import("./../../../src/pages/members/index.js" /* webpackChunkName: "component---src-pages-members-index-js" */),
  "component---src-pages-members-members-js": () => import("./../../../src/pages/members/members.js" /* webpackChunkName: "component---src-pages-members-members-js" */),
  "component---src-pages-members-order-history-js": () => import("./../../../src/pages/members/order-history.js" /* webpackChunkName: "component---src-pages-members-order-history-js" */),
  "component---src-pages-members-profile-js": () => import("./../../../src/pages/members/profile.js" /* webpackChunkName: "component---src-pages-members-profile-js" */),
  "component---src-pages-members-sponsors-js": () => import("./../../../src/pages/members/sponsors.js" /* webpackChunkName: "component---src-pages-members-sponsors-js" */),
  "component---src-pages-past-committee-js": () => import("./../../../src/pages/past-committee.js" /* webpackChunkName: "component---src-pages-past-committee-js" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-register-online-account-js": () => import("./../../../src/pages/register-online-account.js" /* webpackChunkName: "component---src-pages-register-online-account-js" */),
  "component---src-pages-sponsors-js": () => import("./../../../src/pages/sponsors.js" /* webpackChunkName: "component---src-pages-sponsors-js" */),
  "component---src-pages-tamil-school-js": () => import("./../../../src/pages/tamil-school.js" /* webpackChunkName: "component---src-pages-tamil-school-js" */)
}

