import "./src/scss/kyts.scss"
import "jquery/dist/jquery"
import "popper.js/dist/popper"
import "bootstrap/dist/js/bootstrap"

export const onServiceWorkerUpdateReady = () => {
  const answer = window.confirm(
      `This Website has been updated. ` +
      `Reload to display the latest version?`
  )
  if (answer === true) {
      window.location.reload()
  }
}